import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../utils/firebase";
import {useCallback, useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";
import DrawerAppBar from "./DrawerAppBar";
import {CircularProgress} from "@mui/material";
import ShowProgressbar from "./Progressbar";
import {BASE_URL} from "../utils/constants";
import Button from "@mui/material/Button";
import ContestList from "./ContestList";

export default function Contests() {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [contestData, setContestData] = useState<any>([]);
    const [loadingPage, setLoadingPage] = useState(true)
    useEffect(() => {
    user?.getIdToken().then((token) =>
        fetch(BASE_URL+"/twirp/quizmaster.pb.contest.QuizMaster/FetchContests", {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
          body:JSON.stringify({"user_id":user?.uid})
        })
        .then(response => { return response.json()})
        .then(data => {
            setLoadingPage(false)
          setContestData(data.contests);
        })
        .catch(err => {
        })
    );
    }, [user]);

    function handleClick(contestData:any) {
        if(contestData.participated == "true" && contestData.ongoing == "false"){
            navigate("/history/"+contestData.id,{
                state: {
                    contestId: contestData.id,
                }
            });
        }
        else{
            navigate("/contest/"+contestData.id,{
                state: {
                    contestId: contestData.id,
                }
            });
        }
    }


    return(
        <div className="ct" style={{justifyContent:"center"}}>
            {ShowProgressbar(loadingPage)}
                 {ContestList(contestData)}
        </div>
    )
}