import React from 'react'
import StopWatch from '../assets/images/stopwatch.png'
import {useNavigate} from "react-router-dom";

const CountDownTimer = ({hoursMinSecs}: {hoursMinSecs:any}) => {

    const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);
    const navigate = useNavigate();

    const tick = () => {

        if (hrs === 0 && mins === 0 && secs === 0)
            reset()
        else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };


    const reset = () => navigate("/contests");


    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });


    return (
        <div style={{display:"flex", justifyContent:"left", alignItems:"center", paddingLeft:"8px"}}>
            <img src={StopWatch} alt={"Time remaining:"} style={{width:"40px", height:"40px"}}/>
            <h3 style={{color:"white", width:"60px"}}>{`${mins
                .toString()
                .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}</h3>
        </div>
    );
}

export default CountDownTimer;