import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {BASE_URL} from "../utils/constants";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../utils/firebase";

function createData(
    rank: number,
    name: string,
    score: number,
) {
    return { rank, name, score};
}

const rows = [
    createData(1, "159", 6.0),
    createData(2, "237", 9.0),
    createData(3, "262", 16.0),
    createData(4, "305", 3.7),
    createData(5, "356", 16.0),
];

export default function BasicTable(contestId:string) {

    const [rankTable, setRankTable] = useState<any>();
    const [user, loading, error] = useAuthState(auth);
    const [tableDataLoading, setTableDataLoading] = useState(true);

    useEffect(() => {
        user?.getIdToken().then((token) =>
            fetch(BASE_URL + "/twirp/quizmaster.pb.contest.QuizMaster/FetchRank", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify({"contest_id": contestId})
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setRankTable(data);
                    setTableDataLoading(false);
                })
                .catch(err => {
                })
        );
    }, [user]);

    function getTableBody(){
        if(!tableDataLoading){
            return(
                <TableBody>
                    {rankTable.scores.map((row: {
                        user_id: string;
                        rank: boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; score: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;}, k:number) => (
                        <TableRow
                            style={{backgroundColor:row.user_id==user?.uid?"#189AB4":"",}}
                            // key={row.rank}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {k+1}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.score}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            )
        }
    }
    return (
        <TableContainer component={Paper}>
            <Table
                sx={{ width: "100%", height:"100%"}}
                aria-label="simple table">
                <TableHead>
                    <TableRow style={{backgroundColor:"#05445E", fontStyle:"bold"}}>
                        <TableCell sx={{color:"white"}} align="center">Rank</TableCell>
                        <TableCell sx={{color:"white"}} align="center">Name</TableCell>
                        <TableCell sx={{color:"white"}} align="center">Score</TableCell>
                    </TableRow>
                </TableHead>
                {getTableBody()}
            </Table>
        </TableContainer>
    );
}
