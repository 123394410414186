import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contests from "./components/Contests";
import Privacy from "./components/Privacy";
import ContestDetails from "./components/ContestDetails";
import Login from "./components/Login";
import History from "./components/History"
import './App.css';
import ContestDetailsHistory from "./components/ContestDetailsHistory";
import DrawerAppBar from "./components/DrawerAppBar";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <Router>
              <DrawerAppBar/>
              <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/contests" element={<Contests />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/contest/:id" element={<ContestDetails />} />
                  <Route path="/history" element={<History/>}/>
                  <Route path="/history/:id" element={<ContestDetailsHistory />} />
              </Routes>
          </Router>
      </header>
    </div>
  );
}

export default App;
