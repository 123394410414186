import * as React from "react";
import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../utils/firebase";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DrawerAppBar from "./DrawerAppBar";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion, AccordionSummary,
    AccordionDetails,
    ClickAwayListener,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Paper,
    styled,
    ThemeProvider,
    useMediaQuery
} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import ShowProgressbar from "./Progressbar";
import Grid from "@mui/material/Grid";
import {BASE_URL} from "../utils/constants";
// import CountDownTimer from "../utils/CountdownTimer";
import useCheckMobileScreen from "../utils/checkMobileScreen";
import ContestDetailsHistory from "./ContestDetailsHistory";
import CountDownTimer from "../utils/CountdownTimer";

export default function ContestDetails() {
    const location = useLocation();
    const theme = createTheme({
            palette: {
                secondary: {
                    main: '#05445E'
                }
            }
        })
    ;
    let contestId = location.state.contestId
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [contest, setContestData] = useState<any>({});
    const [submissions, setSubmissions] = useState<any>([]);
    const [bgColorCard, setBgColorCard] = useState<number[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [contestDetailsLoading, setContestDetailsLoading] = useState(true);
    const [timerData, setTimerData] = useState<any>();
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setCancelDialogOpen(false);
        setIsLoading(false)
    };


    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const handleChange = (event: React.MouseEvent<HTMLButtonElement>, qid: number) => {
        const newSubmissions = [...submissions]
        const o = newSubmissions.find((a: { qid: string; }) => a.qid === qid.toString());
        if(o !== undefined) {
            if(o.attempted_answer === (event.target as HTMLInputElement).value){
                const index = newSubmissions.indexOf(o);
                if (index > -1) { // only splice array when item is found
                    newSubmissions.splice(index, 1); // 2nd parameter means remove one item only
                }

                const indexBg = bgColorCard.indexOf(Number(o.qid));
                if (indexBg > -1) { // only splice array when item is found
                    bgColorCard.splice(indexBg, 1); // 2nd parameter means remove one item only
                }
            } else {
                o.attempted_answer = (event.target as HTMLInputElement).value
            }
            setSubmissions(newSubmissions);
        } else {
            setSubmissions([...submissions, {"qid":qid.toString(), "attempted_answer":(event.target as HTMLInputElement).value}]);
            setBgColorCard([...bgColorCard, qid]);
        }
    };

    function getRadioValue(qid:number){
        const o = submissions.find((a: { qid: string; }) => a.qid === qid.toString());
        if(o !== undefined) {
            return o.attempted_answer
        }
        else return ""
    }

    const handleDialog = () => {
        setDialogOpen(true);
    };

    const handleCancelDialog = () => {
        setCancelDialogOpen(true);
    }

    const handleSubmit = () => {
        setIsLoading(true);
        user?.getIdToken().then((token) =>
            fetch(BASE_URL+"/twirp/quizmaster.pb.contest.QuizMaster/FinishContest", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify({"user_id":user?.uid, "contest_id": contestId, "submissions":submissions})
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setDialogOpen(false);
                    navigate("/history/"+contestId,{
                        state: {
                            contestId: contestId,
                        }
                    });
                })
                .catch(err => {
                })
        );
    }

    const handleCancelContest = () => {
        setIsLoading(false)
        navigate("/contests");
    }

    function setTimer(data: { time_left_in_seconds: string; }){
        let delta = Number(data.time_left_in_seconds);
        // console.log("delta", delta, data.time_left_in_seconds)
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        const seconds = delta % 60;
        setTimerData({
            hours:hours,
            minutes: minutes,
            seconds: seconds
        })
    }

    useEffect(() => {
        user?.getIdToken().then((token) =>
            fetch(BASE_URL+"/twirp/quizmaster.pb.contest.QuizMaster/StartContest", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify({"contest_id":contestId, "user_id":user?.uid, "user_name":user?.displayName})
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setTimer(data)
                    setContestData(data);
                    // if(contest.time_left_in_seconds=="0"){
                    //     navigate("/contests");
                    // }
                    setContestDetailsLoading(false);
                })
                .catch(err => {
                })
        );
    }, [user]);


    function loadProgressBar() {
        if (isLoading) {
            return (
                <ThemeProvider theme={theme}>
                    <LinearProgress color="secondary"/>
                </ThemeProvider>
            )
        }
    }

    function contestSummaryMobile() {
        if (!contestDetailsLoading) {
            if(contest.time_left_in_seconds != "0"){
            return (
                // <div>
                <Accordion elevation={10}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel1a-header"
                        sx={{backgroundColor: "#08678E",}}
                    >
                        <Typography color={"white"}>Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: "#D4F1F4",}}>
                        <Box sx={{flexGrow: 1}}>
                            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}
                                  sx={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      paddingTop: "16px",
                                      paddingLeft: "8px"
                                  }}>
                                {contest.contest.questions.map((question: { statement: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; options: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[]; }, k: number) => (
                                    <Card sx={{
                                        width: "48px",
                                        height: "48px",
                                        margin: "8px",
                                        backgroundColor: bgColorCard.includes(k + 1) ? "#189AB4" : "white"
                                    }}>

                                        <CardContent>
                                            <Typography color={bgColorCard.includes(k + 1) ? "white" : "black"}>
                                                {k + 1}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )
        }
    }
        else{
            return (
                <div/>
            )
        }
    }
    function contestSummary() {
        if (!contestDetailsLoading) {
            if(contest.time_left_in_seconds != "0"){
            return (
                // <div>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}
                          sx={{alignContent: "center", justifyContent: "center", paddingTop: 10, paddingLeft: 10}}>
                        {contest.contest.questions.map((question: { statement: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; options: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[]; }, k: number) => (
                            <Card key={k} sx={{
                                width: "48px",
                                height: "48px",
                                margin: "8px",
                                backgroundColor: bgColorCard.includes(k + 1) ? "#189AB4" : "white"
                            }}>

                                <CardContent>
                                    <Typography>
                                        {k + 1}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Box>
            )
        }
    }
        else{
            return (
                <div/>
            )
        }
    }

    function getContestDetails(){
        if (!contestDetailsLoading) {
            if (contest.time_left_in_seconds != "0") {
            return (
                <div className={"contest-qstn"} style={{
                    width: isMobile ? "100%" : "70%",
                    height: "100vh",
                    overflow: "scroll",
                    paddingBottom: 100
                }}>
                    <Dialog
                        fullScreen={false}
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        PaperProps={{
                            style: {
                                backgroundColor: "#D4F1F4",
                            },
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            {loadProgressBar()}
                        </ThemeProvider>
                        <DialogTitle id="responsive-dialog-title">
                            {"Submit answers and end contest now?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You have attempted {submissions.length} out
                                of {contest.contest.questions.length} questions.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleSubmit}
                                    sx={{
                                        color: "#05445E",
                                        borderColor: "white",
                                        // margin: "8px",
                                        '&:hover': {
                                            borderColor: "#189AB4",
                                            // color: "white"
                                        },
                                        // marginRight: "16px"
                                    }}
                            >
                                YES
                            </Button>
                            <Button onClick={handleClose} autoFocus
                                    sx={{
                                        color: "#05445E",
                                        borderColor: "white",
                                        // margin: "8px",
                                        '&:hover': {
                                            borderColor: "#189AB4",
                                            // color: "white"
                                        },
                                        // marginRight: "16px"
                                    }}
                            >
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={false}
                        open={cancelDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        PaperProps={{
                            style: {
                                backgroundColor: "#D4F1F4",
                            },
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            {loadProgressBar()}
                        </ThemeProvider>
                        <DialogTitle id="responsive-dialog-title">
                            {"End contest without submitting answers?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You have attempted {submissions.length} out
                                of {contest.contest.questions.length} questions.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleCancelContest}
                                    sx={{
                                        color: "#05445E",
                                        borderColor: "white",
                                        // margin: "8px",
                                        '&:hover': {
                                            borderColor: "#189AB4",
                                            // color: "white"
                                        },
                                        // marginRight: "16px"
                                    }}
                            >
                                YES
                            </Button>
                            <Button onClick={handleClose} autoFocus
                                    sx={{
                                        color: "#05445E",
                                        borderColor: "white",
                                        // margin: "8px",
                                        '&:hover': {
                                            borderColor: "#189AB4",
                                            // color: "white"
                                        },
                                        // marginRight: "16px"
                                    }}
                            >
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {contest.contest.questions.map((question: { statement: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; options: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[]; }, k: number) => (
                        <Card key={k} sx={{borderRadius: 0,}}>
                            <CardContent sx={{alignContent: "left"}}>
                                <Typography  variant="h6" component="div" sx={{textAlign: "left"}}>
                                    {k + 1}. {question.statement}
                                </Typography>
                                <FormControl sx={{display: "flex", alignContent: "start", textAlign:"left"
                                    // color: "white"
                                }}>
                                    <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        value={getRadioValue(k + 1)}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="1" control={<Radio
                                            sx={{
                                                // color: "white",
                                                '&.Mui-checked': {
                                                    color: "#05445E",
                                                },
                                            }}
                                            onClick={(e) => {
                                                handleChange(e, k + 1)
                                            }}

                                        />} label={question.options[0]}/>
                                        <FormControlLabel value="2" control={<Radio
                                            sx={{
                                                // color: "white",
                                                '&.Mui-checked': {
                                                    color: "#05445E",
                                                },
                                            }}
                                            onClick={(e) => {
                                                handleChange(e, k + 1)
                                            }}

                                        />} label={question.options[1]}/>
                                        <FormControlLabel value="3" control={<Radio
                                            sx={{
                                                // color: "white",
                                                '&.Mui-checked': {
                                                    color: "#05445E",
                                                },
                                            }}
                                            onClick={(e) => {
                                                handleChange(e, k + 1)
                                            }}

                                        />} label={question.options[2]}/>
                                        <FormControlLabel value="4" control={<Radio
                                            sx={{
                                                // color: "white",
                                                '&.Mui-checked': {
                                                    color: "#05445E",
                                                },
                                            }}
                                            onClick={(e) => {
                                                handleChange(e, k + 1)
                                            }}

                                        />} label={question.options[3]}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                    ))}
                    <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, bgcolor: "#05445E"}} elevation={3}>
                        <Box sx={{width: "100%", bgcolor: "#05445E"}}>
                            <BottomNavigation
                                sx={{display: "flex", width: "100%", bgcolor: "#05445E"}}>
                                <div style={{flex: 1}}>
                                    {<CountDownTimer hoursMinSecs={timerData}/>}
                                </div>
                                <div>
                                    <Button variant="contained"
                                            sx={{
                                                bgcolor: "#189AB4",
                                                margin: "8px",
                                                '&:hover': {backgroundColor: "#05445E"},
                                            }}
                                            onClick={handleDialog}
                                    >Submit</Button>
                                    <Button variant="outlined"
                                            sx={{
                                                color: "#189AB4",
                                                borderColor: "white",
                                                margin: "8px",
                                                '&:hover': {borderColor: "#189AB4", color: "white"},
                                                marginRight: "16px"
                                            }}
                                            onClick={handleCancelDialog}
                                    >Cancel</Button>
                                </div>
                            </BottomNavigation>
                        </Box>
                    </Paper>
                </div>
            )
        }
            else{
                return (
                    <ContestDetailsHistory/>
                )
            }
        }
    }
    return(
        <div >
            {/*<DrawerAppBar/>*/}
            {ShowProgressbar(contestDetailsLoading)}
            <div className={"contest-qstn-container"} style={{display:isMobile?"":"flex"}}>
                {isMobile? "":getContestDetails()}
                <div className={"contest-summary"} style={{height:isMobile?"":"40%", overflow:"scroll", width:isMobile?"100%":"40%"}}>  {isMobile?contestSummaryMobile():contestSummary()}</div>
              {isMobile? getContestDetails():""}
            </div>
        </div>
    )
}