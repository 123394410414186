import {useCallback, useEffect, useRef, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../utils/firebase";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import DrawerAppBar from "./DrawerAppBar";
import {useLocation, useNavigate} from "react-router-dom";
import ShowProgressbar from "./Progressbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {BASE_URL} from "../utils/constants";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContestRank from "./ContestRank"
import BasicTable from "./ContestRank";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function ContestDetailsHistory() {

    interface Question {
        statement: string;
        options: string[];
        marks: number;
        answer: string[];
        attempted_answer: string[];
    }

    interface Contest {
        name: string;
        duration: number;
        start_time: Date;
        questions: Question[];
        total_marks: number;
        id: string;
    }

    interface ContestHistory {
        contest: Contest;
        score: string;
    }


    const location = useLocation();
    let contestId = location.state.contestId
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [contest, setContestData] = useState<any>({});
    const [contestDetailsLoading, setContestDetailsLoading] = useState(true);
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;


    useEffect(() => {
        user?.getIdToken().then((token) =>
            fetch(BASE_URL + "/twirp/quizmaster.pb.contest.QuizMaster/FetchParticipationHistoryDetail", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify({"contest_id": contestId, "user_id": user?.uid})
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setContestData(data);
                    setContestDetailsLoading(false);
                })
                .catch(err => {
                })
        );
    }, [user]);

    function getEvalColor(question: { answer: string[]; attempted_answer: any; statement?: string | number | boolean | React.ReactFragment | React.ReactPortal | React.ReactElement<any, string | React.JSXElementConstructor<any>> | null | undefined; options?: (string | number | boolean | React.ReactFragment | React.ReactPortal | React.ReactElement<any, string | React.JSXElementConstructor<any>> | null | undefined)[]; }) {

        if (question.attempted_answer == undefined) {
            return "gray"
        }
        if (question.attempted_answer.toString() === question.answer.toString()) {
            return "#008A7E"
        }
        return "#C6466B"
    }

    function contestSummaryMobile() {
        if (!contestDetailsLoading) {
            return (
                <Accordion elevation={10}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel1a-header"
                        sx={{backgroundColor: "#08678E",}}
                    >
                        <Typography color={"white"} variant={"h5"}>Score: {contest.score}/{contest.contest.total_marks}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{}}>
                        <Box sx={{flexGrow: 1}}>
                            {/*<h4>Score: {contest.score}/{contest.contest.total_marks}</h4>*/}
                            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}
                                  sx={{
                                      alignContent: "center",
                                      justifyContent: "center",
                                      paddingTop: "16px",
                                      paddingLeft: "8px"
                                  }}>
                                {contest.contest.questions.map((question: {
                                    answer: string[];
                                    attempted_answer: string[];
                                    statement: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; options: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[];
                                }, k: number) => (
                                    <Card sx={{
                                        width: "48px",
                                        height: "48px",
                                        margin: "8px",
                                        backgroundColor: getEvalColor(question)
                                    }}>

                                        <CardContent>
                                            <Typography color={"white"}>
                                                {k + 1}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Box>
                        {/*{getContestHistoryDetails()}*/}
                    </AccordionDetails>
                </Accordion>
            )
        }
    }

    function contestSummary() {
        if (!contestDetailsLoading) {
            return (
                // <div>
                <Box sx={{flexGrow: 1}}>
                    <h3>Score: {contest.score}/{contest.contest.total_marks}</h3>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}
                          sx={{alignContent: "center", justifyContent: "center", padding: 4}}>
                        {contest.contest.questions.map((question: {
                            answer: string[];
                            attempted_answer: string[];
                            statement: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; options: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[]; }, k: number) => (
                            <Card key={k} sx={{width: "48px", height: "48px", margin: "8px", backgroundColor:getEvalColor(question)}}>

                                <CardContent>
                                    <Typography color={"white"}>
                                        {k + 1}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Box>
            )
        }
    }

    function getContestHistoryDetails(){
        if (!contestDetailsLoading){
            return(
                <div className={"contest-qstn"} style={{width:isMobile?"100%":"70%", height:"100vh", overflow:"scroll"}}>
                    {contest.contest.questions.map((question:Question,k: number) => (
                        <Card  key={k} sx={{borderRadius: 0, }}>
                            <CardContent sx={{alignContent: "left"}}>
                                <Typography  variant="h6" component="div" sx={{textAlign:"left"}}>
                                    {k+1}. {question.statement}
                                </Typography>

                                <Typography   component="div" sx={{textAlign:"left"}}>
                                    <br/>
                                    A. {question.options[0]}
                                    <br/>
                                    B. {question.options[1]}
                                    <br/>
                                    C. {question.options[2]}
                                    <br/>
                                    D. {question.options[3]}
                                    <br/>
                                    <br/>
                                    Correct Answer: {question.answer[0]}
                                    <br/>
                                    Your Answer: {question.attempted_answer}
                                </Typography>

                            </CardContent>
                        </Card>
                    ))}
                </div>
            )
        }
    }
    const [value, setValue] = React.useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return(
        <div >
            {/*<DrawerAppBar/>*/}
            {ShowProgressbar(contestDetailsLoading)}
            <TabContext value={value.toString()}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <TabList variant={"fullWidth"} onChange={handleChange} aria-label="lab API tabs" sx={{justifyContent:"space-evenly" }}>
                        <Tab label="Leaderboard" value="1"/>
                        <Tab label="Answers" value="2" />
                        <Tab label="Summary" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{padding:0}}>
                    <div className={"contest-summary"} style={{height:isMobile?"":"40%", overflow:"scroll", width:isMobile?"100%":"40%"}}>
                        {/*{isMobile?contestSummaryMobile():contestSummary()}*/}
                        {isMobile? BasicTable(contestId):BasicTable(contestId)}
                    </div>
                </TabPanel>
                <TabPanel value="2" sx={{padding:0}}>
                    <div className={"contest-qstn-container"} style={{display:isMobile?"":"flex"}}>
                        {getContestHistoryDetails()}
                    </div>
                </TabPanel>
                <TabPanel value="3" sx={{padding:0}}>
                    <div className={"contest-qstn-container"} style={{display:isMobile?"":"flex"}}>
                        {contestSummary()}
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    )
}