import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KdsLogo from '../assets/images/quiz_logo.png'
import ProfileIcon from "@mui/icons-material/AccountCircle";
import {Menu, MenuItem} from "@mui/material";
import {auth, db, logout} from "../utils/firebase";
import {signOut} from "firebase/auth";
import GoogleTranslate from "./GoogleTranslate";

const drawerWidth = 250;
const navItems = [
    'Dashboard',
    'Privacy Policy'
    // 'History',
];

function DrawerAppBar() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        signOut(auth).then(() => navigate('/'));
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function handleButtonClick(item: string) {
        if (item === "Dashboard"){
            navigate("/contests");
        } else if (item === "History"){
            navigate("/history");
        }else if (item === "Privacy Policy"){
            navigate("/privacy");
        } else{
            navigate("/")
        }
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    //
    // const DesktopToolbar=(
    //
    // )

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', bgcolor: "#05445E", height:"100%"}}>
        <div style={{display:"flex"}}>
            <img width="30%"
                src={KdsLogo}
            />
            <Typography variant="h4" sx={{ my: 2, color: '#75E6DA', textAlign: 'center'}}>
                KwizKosh
            </Typography>
        </div>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'left', color: '#75E6DA'}} onClick={e => handleButtonClick(item)}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItemButton sx={{ textAlign: 'left', color: '#75E6DA'}} onClick={e => handleLogout()}>
                    <ListItemText primary={"Logout"} />
                </ListItemButton>
            </List>
        </Box>
    );


    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" sx={{ bgcolor: "#05445E" }} position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } , color: '#75E6DA'}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        component="img"
                        sx={{
                            height: 44,
                            borderRadius: 10,
                            display: {
                                xs: 'none', sm: 'block', color: '#75E6DA'
                            } ,
                        }}
                        src={KdsLogo}
                    />
                    <Typography
                        variant="h4"
                        component="div"
                        sx={
                        {
                            flexGrow: 1,
                            display: {
                            xs: 'none', sm: 'block', color: '#75E6DA'
                            } ,
                            textAlign:'left',
                            paddingLeft: 5
                        }
                    }
                    >
                        KwizKosh
                    </Typography>
                    <GoogleTranslate/>

                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item} sx={{ color: '#75E6DA' }}  onClick={e => handleButtonClick(item)}>
                                {item}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{display: {
                            xs: 'none', sm: 'block', color: '#75E6DA'
                        } ,}}>
                        <IconButton color="primary"
                                    aria-label="Account"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}>
                            <ProfileIcon sx={{ fontSize: "40px", color:"#75E6DA" }} />
                        </IconButton >
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{"& .MuiPaper-root": {
                                backgroundColor: "#D4F1F4"
                            }}}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Box component="nav" >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>

    );
}

export default DrawerAppBar;
