import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "../layouts/Login.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Image} from "@mui/icons-material";
import KdsLogo from '../assets/images/quiz_logo.png'

function Login() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user?.uid) {
        // console.log(user.getIdToken())
        navigate("/contests");
    }
  }, [user, loading]);

  return (
    <div className="login">
      <div className="login__container">
    {/*<div style={{backgroundColor:"#D4F1F4"}}>*/}
        <Card sx={{width:350, height:300, backgroundColor: "#189AB4",}}>
          <CardContent>
              <img src={KdsLogo} style={{width:100, height:100}}/>
            <Typography variant="h5" component="div">
              Welcome To KwizKosh
            </Typography>
            <Typography variant="h6">
              Participate in quizzes and get detailed insights into your strengths and weaknesses.
            </Typography>
          </CardContent>
        </Card>
          <Button className="login__btn login__google" variant={"contained"}
                      sx={{width:350, bgcolor: "#05445E", marginTop:"16px", '&:hover': {backgroundColor: "#189AB4"}}}
                  onClick={signInWithGoogle}
              >
                  sign in with google
              </Button>
        {/*<button className="login__btn login__google" onClick={signInWithGoogle}>*/}
        {/*  Login with Google*/}
        {/*</button>*/}
      </div>
    </div>
  );
}

export default Login;
