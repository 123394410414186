import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import * as React from "react";

export default function ShowProgressbar(loadingPage: boolean){
    if(loadingPage){
        return(
            <div className={"login"}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{color:"#05445E"}}/>
                </Box>
            </div>
        )
    }
}