import * as React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import GroupIcon from '@mui/icons-material/Group';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {Runtime} from "inspector";
import StartQuizDialog from "./StartQuizDialog";
import {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export default function ContestList(contestData: any) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContestId, setDialogContestId] = useState<any>("");
    const handleClose = () => {
        setDialogOpen(false);
    };
    const navigate = useNavigate();
    function handleClick(contestData:any) {
        if(contestData.participated == "true" && contestData.ongoing == "false"){
            navigate("/history/"+contestData.id,{
                state: {
                    contestId: contestData.id,
                }
            });
        }
        else{
            setDialogOpen(true);
            setDialogContestId(contestData.id)
        }
    }

    function getContestDate(start_time:string){
        const contest_date = new Date(start_time)
        const date = new Date()
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const contest_month = contest_date.getMonth()+1
        const contest_year = contest_date.getFullYear()
        const utc1 = Date.UTC(contest_date.getFullYear(), contest_date.getMonth(), contest_date.getDate());
        const utc2 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        const diff = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        if (diff == 0){
            return "Today"
        } else if (diff == 1){
            return "Yesterday"
        }
        return contest_date.getDate()+"/"+contest_month+"/"+contest_year
    }

    function handleConfirmation(){
        setDialogOpen(false)
        navigate("/contest/"+dialogContestId,{
            state: {
                contestId: dialogContestId,
            }
        });
    }

    function startQuizDialog(){
        return(
            <div>
                <Dialog
                    fullScreen={false}
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    PaperProps={{
                        style: {
                            backgroundColor: "#D4F1F4",
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Start Quiz?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You can attempt this quiz only once.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleConfirmation}
                                sx={{
                                    color: "#05445E",
                                    borderColor: "white",
                                    // margin: "8px",
                                    '&:hover': {
                                        borderColor: "#189AB4",
                                        // color: "white"
                                    },
                                    // marginRight: "16px"
                                }}
                        >
                            YES
                        </Button>
                        <Button onClick={handleClose} autoFocus
                                sx={{
                                    color: "#05445E",
                                    borderColor: "white",
                                    // margin: "8px",
                                    '&:hover': {
                                        borderColor: "#189AB4",
                                        // color: "white"
                                    },
                                    // marginRight: "16px"
                                }}
                        >
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }

    return(
        <div>
            {startQuizDialog()}
            {contestData.map((contestData: {
                ongoing: string;
                subject: string;
                topic: string;
                participated: string;
                id: string ;
                name: string;
                total_marks: string | number ;
                duration: string | number ;
                start_time:string},
                              k: React.Key | null | undefined) => (
                <List sx={{maxWidth:"600px"}}>
                    <ListItem disablePadding>
                        <ListItemButton  disableRipple={true}>
                            <ListItemText
                                primary={contestData.topic}
                                secondary={
                                    <div>
                                        <div>{contestData.subject}</div>
                                        <div>{contestData.total_marks} Questions | {contestData.duration} minutes</div>
                                    </div>
                                }/>
                            <IconButton disableRipple={true} edge="end" aria-label="delete">
                                <ListItemText secondary={
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>{getContestDate(contestData.start_time)}</div>
                                        <div style={{display: "flex", marginTop: "16px", justifyContent: "flex-end"}}>
                                            <Button sx={{bgcolor: contestData.participated == "true" && contestData.ongoing == "false"?"#D4F1F4":"#75E6DA", color: "#05445E"}} onClick={() => {
                                                handleClick(contestData)
                                            }}>
                                                {contestData.participated == "true" && contestData.ongoing == "false" ? "View Score" : "Start Quiz"}
                                            </Button>
                                            {/*<Typography align={"right"}> 133 </Typography>*/}
                                        </div>
                                    </div>
                                }/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </List>
            ))}
        </div>
    )
}