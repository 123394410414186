import DrawerAppBar from "./DrawerAppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../utils/firebase";
import '../layouts/History.css'
import {useNavigate} from "react-router-dom";
import ShowProgressbar from "./Progressbar";
import {BASE_URL} from "../utils/constants";

export default function History() {

    const [historyData, setHistoryData] = useState<any>([]);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [isPageLoading, setIsPageLoading] = useState(true);
    useEffect(() => {
        user?.getIdToken().then((token) =>
            fetch(BASE_URL+"/twirp/quizmaster.pb.contest.QuizMaster/FetchParticipationHistory", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify({"user_id":user?.uid})
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setHistoryData(data.contest);
                    setIsPageLoading(false);
                })
                .catch(err => {
                })
        );
    }, [user]);

    function handleCardClick(contest_id: any) {
        navigate("/history/"+contest_id,{
            state: {
                contestId: contest_id,
            }
        });
    }

    function getHistory(){
        if (!isPageLoading && historyData!=undefined){
            return(
                <Box sx={{ flexGrow: 1, }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{alignContent:"center", justifyContent:"center", }}>
                        {historyData.map((historyData: {
                            id: string;
                            name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; total_marks: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; duration: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; start_time: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, k: React.Key | null | undefined) => (
                            <Card key={k} sx={{width:350, backgroundColor: "#189AB4", margin: "32px", textAlign:"left",}}
                                  onClick={() => {handleCardClick(historyData.id)}}>
                                <CardContent >
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Sample Subject
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {historyData.name}
                                    </Typography>
                                    <Typography sx={{mb: 1.5}} color="text.secondary">
                                        Sample Topic
                                    </Typography>
                                    <Typography variant="body2">
                                        Total Marks : {historyData.total_marks}
                                        <br/>
                                        Duration : {historyData.duration} minutes
                                        <br/>
                                        {/*Participated At : {historyData.start_time}*/}
                                        <br/>
                                        {/*Score : {historyData.total_marks}*/}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Box>
            )
        }
        else{
            return (
                <div className={"login"}>
                    You have not participated in any contests yet!
                </div>

            )
        }
    }
    return (
        <div className="ct">
            <DrawerAppBar/>
            {ShowProgressbar(isPageLoading)}
            {getHistory()}
        </div>
    )
}